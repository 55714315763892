import { useMemo } from 'react';

import {
  Autocomplete,
  Box,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../app/hooks';

import { selectDevs } from '../features/dev/devsSlice';

import { fetchIssues } from '../features/issues/issuesSlice';

import { selectMilestones } from '../features/milestones/milestonesSlice';

import {
  selectMilestone,
  setMilestone,
} from '../features/milestones/milestoneSlice';

import { clearDev, setDev } from '../features/dev/devSlice';

import { clearProject, setProject } from '../features/project/projectSlice';

import { Dev, Project } from '../types';

import { selectProjects } from '../features/project/projectsSlice';
import { reverseArray } from '../utils/reverseArray';

function Searches(): JSX.Element {
  const dispatch = useAppDispatch();

  const devs = useAppSelector(selectDevs);
  const projects = useAppSelector(selectProjects);

  const milestone = useAppSelector(selectMilestone);
  const milestones = useAppSelector(selectMilestones);

  const handleSetDev = (idDev: number) => {
    const dev = devs.find(dev => dev.id === idDev);

    if (dev) dispatch(setDev(dev));
  };

  const optionsMilestone = useMemo(
    () =>
      milestones.map(project => ({
        label: project.title,
        id: project.id,
      })),
    [milestones],
  );

  const optionsMilestoneReversed = reverseArray(optionsMilestone);

  const optionsDevs = useMemo(() => {
    if (devs.length > 0) {
      return devs
        .map((dev: Dev) => ({
          label: dev.name,
          id: dev.id,
        }))
        .sort((a, b) => {
          const x = a.label.toLowerCase();
          const y = b.label.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
    }
    return [];
  }, [devs]);

  const optionsProjects = useMemo(() => {
    if (projects.length > 0) {
      return projects
        .map((project: Project) => ({
          label: project.name,
          id: project.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }
    return [];
  }, [projects]);

  if (
    optionsMilestoneReversed.length === 0 ||
    optionsDevs.length === 0 ||
    optionsProjects.length === 0
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack
      sx={{ alignItems: 'center', justifyContent: 'center', mb: 4, mt: 2 }}
      direction={{ xs: 'column', sm: 'column', md: 'row' }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
    >
      <Autocomplete
        disablePortal
        disableClearable
        id="combo-box-demo"
        size="small"
        sx={{ width: 300 }}
        value={optionsMilestoneReversed.find(m => m.label === milestone)}
        options={optionsMilestoneReversed}
        renderInput={params => <TextField {...params} label="Períodos" />}
        onChange={(event, value) => {
          if (value) {
            dispatch(fetchIssues({ milestone: value.label, devs }));
            dispatch(setMilestone(value.label));
          }
        }}
      />
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        sx={{ width: 300 }}
        options={optionsProjects}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => <TextField {...params} label="Projetos" />}
        onChange={(event, value) => {
          if (value) dispatch(setProject({ id: value.id, name: value.label }));
        }}
        onInputChange={() => dispatch(clearProject())}
      />

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        sx={{ width: 300 }}
        options={optionsDevs}
        renderInput={params => (
          <TextField {...params} label="Desenvolvedores" />
        )}
        onChange={(event, value) => {
          if (value) handleSetDev(value.id);
        }}
        onInputChange={() => dispatch(clearDev())}
      />
    </Stack>
  );
}

export default Searches;
