import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

import { Project } from '../../types';

export interface ProjectState {
  data: Project;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ProjectState = {
  data: { id: 0, name: '' },
  status: 'idle',
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<Project>) => {
      state.data = action.payload;
    },
    clearProject: state => {
      state.data = initialState.data;
    },
  },
});

export const { setProject, clearProject } = projectSlice.actions;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectProject = (state: RootState) => state.project.data;

export default projectSlice.reducer;
