import { useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { Issue } from '../types';

import { useAppSelector } from '../app/hooks';

import { selectDev } from '../features/dev/devSlice';
import { selectProject } from '../features/project/projectSlice';

import CardBoard from '../components/CardBoard';

function Boards(): JSX.Element {
  const dev = useAppSelector(selectDev);
  const project = useAppSelector(selectProject);

  const { data: issuesData, status: issuesStatus } = useAppSelector(
    state => state.issues,
  );

  const { data: mergesData, status: mergesStatus } = useAppSelector(
    state => state.mergeRequests,
  );

  const [tasks, setTasks] = useState<Issue[]>([]);

  const filterTasks = () => {
    const issues = [...issuesData, ...mergesData];

    if (project.id !== 0 && dev.id === 0) {
      setTasks(issues.filter(issue => issue.project_id === project.id));
    }

    if (dev.id !== 0 && project.id === 0) {
      console.log('aqui');
      setTasks(issues.filter(issue => issue.assignee.id === dev.id));
    }

    if (project.id !== 0 && dev.id !== 0) {
      setTasks(
        issues.filter(
          issue =>
            issue.assignee.id === dev.id && issue.project_id === project.id,
        ),
      );
    }

    if (project.id === 0 && dev.id === 0) {
      setTasks(issues);
    }
  };

  useEffect(() => {
    if (issuesStatus === 'idle' && mergesStatus === 'idle') {
      filterTasks();
    }
  }, [issuesStatus, mergesStatus]);

  useEffect(() => {
    filterTasks();
  }, [dev, project]);

  const boards = [
    // { label: 'open', name: 'Abertas', color: '#bdbdbd', text_color: '#FFFFFF' },
    {
      label: 'to do',
      name: 'Pendentes',
      color: '#ffa726',
      text_color: '#FFFFFF',
    },
    {
      label: 'doing',
      name: 'Em desenvolvemento',
      color: '#66bb6a',
      text_color: '#FFFFFF',
    },
    {
      label: 'blocked',
      name: 'Bloqueadas',
      color: '#ef5350',
      text_color: '#FFFFFF',
    },
    {
      label: 'close',
      name: 'Concluídas',
      color: '#42a5f5',
      text_color: '#FFFFFF',
    },
  ];

  const issuesToDo = useMemo(() => {
    return tasks.filter(
      issue =>
        issue.state === 'opened' &&
        (issue.labels.indexOf('to do') >= 0 ||
          issue.labels.indexOf('To Do') >= 0),
    );
  }, [tasks]);

  const issuesDoing = useMemo(() => {
    return tasks.filter(
      issue =>
        issue.state === 'opened' &&
        (issue.labels.indexOf('doing') >= 0 ||
          issue.labels.indexOf('Doing') >= 0),
    );
  }, [tasks]);

  const issuesBlocked = useMemo(() => {
    return tasks.filter(
      issue =>
        issue.state === 'opened' &&
        (issue.labels.indexOf('blocked') >= 0 ||
          issue.labels.indexOf('Blocked') >= 0),
    );
  }, [tasks]);

  const issuesClose = useMemo(() => {
    return tasks.filter(
      issue => issue.state === 'closed' || issue.mergerRequest,
    );
  }, [tasks]);

  return (
    <Stack
      direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
      spacing={2}
    >
      {boards.map(board => {
        if (board.label === 'to do') {
          return (
            <CardBoard key={board.label} issues={issuesToDo} board={board} />
          );
        }
        if (board.label === 'doing') {
          return (
            <CardBoard key={board.label} issues={issuesDoing} board={board} />
          );
        }
        if (board.label === 'blocked') {
          return (
            <CardBoard key={board.label} issues={issuesBlocked} board={board} />
          );
        }
        return (
          <CardBoard key={board.label} issues={issuesClose} board={board} />
        );
      })}
    </Stack>
  );
}

export default Boards;
