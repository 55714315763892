import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

import { getDevs } from '../../services/api';

import { Dev } from '../../types';

export interface DevsState {
  data: Dev[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: DevsState = {
  data: [],
  status: 'idle',
};

export const fetchDevs = createAsyncThunk('devs/fetchDevs', async () => {
  const response = await getDevs();
  return response;
});

export const devsSlice = createSlice({
  name: 'devs',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(fetchDevs.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchDevs.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.data = payload;
      });
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectDevs = (state: RootState) => state.devs.data;

export default devsSlice.reducer;
