import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

import { Dev } from '../../types';

export interface DevState {
  data: Dev;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: DevState = {
  data: { id: 0, name: '', username: '', state: '', avatar_url: '' },
  status: 'idle',
};

export const devSlice = createSlice({
  name: 'dev',
  initialState,
  reducers: {
    setDev: (state, action: PayloadAction<Dev>) => {
      state.data = action.payload;
    },
    clearDev: state => {
      state.data = initialState.data;
    },
  },
});

export const { setDev, clearDev } = devSlice.actions;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectDev = (state: RootState) => state.dev.data;

export default devSlice.reducer;
