import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

import { getIssues } from '../../services/api';

import { Dev, Issue } from '../../types';

export interface IssuesState {
  data: Issue[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: IssuesState = {
  data: [],
  status: 'idle',
};

interface Data {
  milestone: string;
  devs: Dev[];
}

export const fetchIssues = createAsyncThunk(
  'issues/fetchIssues',
  async (data: Data) => {
    const response = await getIssues(data.milestone, data.devs);
    return response;
  },
);

export const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(fetchIssues.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchIssues.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.data = payload;
      });
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectIssues = (state: RootState) => state.issues.data;

export default issuesSlice.reducer;
