import axios from 'axios';

import { Dev, Issue, Milestone } from '../types';
import { Projects } from '../utils';

export const api = axios.create({
  baseURL: 'https://gitlab.defensoria.to.def.br/api/v4',
  headers: {
    'PRIVATE-TOKEN': '-SszstDxxwhCtxADuywQ',
  },
});

const fetchAllResources = async (
  resources: string,
  params: object,
): Promise<any> => {
  const asyncIterable = {
    [Symbol.asyncIterator]() {
      return {
        page: '1',
        finished: false,
        async next() {
          if (!this.finished) {
            const response = await api.get(resources, {
              params: { ...params, page: this.page },
            });

            const nextPage = response.headers['x-next-page'] || '';

            this.finished = nextPage === '';
            this.page = nextPage;

            return Promise.resolve({
              value: response,
              done: false,
            });
          }

          this.page = '1';
          this.finished = false;

          return Promise.resolve({
            done: true,
            value: { data: null },
          });
        },
      };
    },
  };

  let results: any = [];

  // eslint-disable-next-line no-restricted-syntax
  for await (const resp of asyncIterable) {
    const { data } = resp;
    results = [...results, ...data];
  }

  return results;
};

export const getIssues = async (
  milestone: string,
  devs: Dev[],
): Promise<Issue[]> => {
  const params = {
    milestone,
    per_page: 100,
    scope: 'all',
    sort: 'asc',
  };

  const results = await fetchAllResources('issues', params);

  const issues = results
    .filter(
      (issue: Issue) =>
        issue.assignee &&
        devs.find(dev => dev.id === Number(issue.assignee.id)),
    )
    .map((issue: Issue) => {
      const project = Projects.find(p => p.id === issue.project_id);
      return {
        ...issue,
        project: {
          id: issue.project_id,
          name: project ? project.label : '',
        },
        mergerRequest: false,
      };
    });
  return issues;
};

export const getMergeRequests = async (
  milestone: string,
  devs: Dev[],
): Promise<Issue[]> => {
  const params = {
    milestone,
    per_page: 100,
    scope: 'all',
    sort: 'asc',
  };

  const results = await fetchAllResources('merge_requests', params);

  const mergeRequests = results
    .filter(
      (mr: Issue) =>
        mr.assignee &&
        devs.find(dev => dev.id === Number(mr.assignee.id)) &&
        mr.time_stats.total_time_spent > 0,
    )
    .map((issue: Issue) => {
      const project = Projects.find(p => p.id === issue.project_id);
      return {
        ...issue,
        project: {
          id: issue.project_id,
          name: project ? project.label : '',
        },
        mergerRequest: true,
      };
    });
  return mergeRequests;
};

export const getDevs = async (): Promise<Dev[] | []> => {
  const response = await api.get('groups/3/members', {
    params: {
      per_page: 100,
      sort: 'name_asc',
    },
  });

  if (response.status === 200 && response.data) {
    return response.data.filter(
      (dev: Dev) =>
        dev.state === 'active' &&
        dev.id !== 1 &&
        dev.id !== 10 &&
        dev.id !== 48 &&
        dev.id !== 106,
    );
  }

  return [];
};

export const getMilestone = async (): Promise<Milestone[] | []> => {
  const response = await api.get(`projects/31/milestones`, {
    params: {
      per_page: 100,
    },
  });

  if (response.status >= 200 && response.status < 300 && response.data) {
    return response.data.filter(
      (milestone: Milestone) =>
        new Date('2023-06-01') < new Date(milestone.start_date),
    );
  }

  return [];
};
