import { useEffect } from 'react';

import { Toolbar } from '@mui/material';

import { BrowserRouter } from 'react-router-dom';

import Routes from './Routes';

import { useAppDispatch, useAppSelector } from './app/hooks';

import { fetchDevs } from './features/dev/devsSlice';

import { fetchIssues } from './features/issues/issuesSlice';

import { fetchMilestones } from './features/milestones/milestonesSlice';

import { fetchMergeRequests } from './features/mergeRequests/mergeRequestsSlice';

import Header from './components/Header';
import Searches from './components/Searches';

import { setProjects } from './features/project/projectsSlice';
import { setMilestone } from './features/milestones/milestoneSlice';

import { Project } from './types';

function App(): JSX.Element {
  const dispatch = useAppDispatch();

  const { data: milestones, status } = useAppSelector(
    state => state.milestones,
  );

  const { data: issues, status: statusIssues } = useAppSelector(
    state => state.issues,
  );

  const { data: devs, status: statusDevs } = useAppSelector(
    state => state.devs,
  );

  useEffect(() => {
    dispatch(fetchMilestones());
  }, [dispatch]);

  useEffect(() => {
    if (status === 'idle' && milestones.length > 0) {
      dispatch(fetchDevs());
      dispatch(setMilestone(milestones[milestones.length - 1].title));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (statusDevs === 'idle' && devs.length > 0) {
      dispatch(fetchIssues({ milestone: milestones[0].title, devs }));
      dispatch(fetchMergeRequests({ milestone: milestones[0].title, devs }));
    }
  }, [dispatch, devs]);

  useEffect(() => {
    if (statusIssues === 'idle' && issues.length > 0) {
      const projects = issues.reduce((acc: Project[], value) => {
        if (!acc.find(acc => acc.id === value.project_id)) {
          acc.push({ id: value.project.id, name: value.project.name });
        }
        return acc;
      }, []);
      dispatch(setProjects(projects));
    }
  }, [dispatch, statusIssues]);

  return (
    <BrowserRouter>
      <Header />

      <Toolbar />

      <Searches />

      <Routes />
    </BrowserRouter>
  );
}

export default App;
