export const Projects = [
  { id: 120, label: 'Astra' },
  { id: 31, label: 'Athenas' },
  { id: 169, label: 'Athenas - Backend' },
  { id: 167, label: 'Athenas - Frontend' },
  { id: 94, label: 'Atualiza' },
  { id: 54, label: 'Avalia' },
  { id: 125, label: 'Certificados' },
  { id: 105, label: 'DevBoard' },
  { id: 48, label: 'DevOps' },
  { id: 135, label: 'Doadômetro' },
  { id: 102, label: 'Editorial' },
  { id: 95, label: 'eSocial' },
  { id: 97, label: 'Eventos' },
  { id: 41, label: 'Helpdesk' },
  { id: 104, label: 'IRRF' },
  { id: 136, label: 'Meet - Backend' },
  { id: 140, label: 'Meet - Frontend' },
  { id: 142, label: 'Meet - jitsi' },
  { id: 149, label: 'Patrimônio App' },
  { id: 212, label: 'Portal - Frontend' },
  { id: 213, label: 'Portal - Backend' },
  { id: 214, label: 'Portal - Requerimentos' },
  { id: 124, label: 'Portal do Servidor - Backend' },
  { id: 153, label: 'Portal do Servidor - Frontend' },
  { id: 5, label: 'Sisref' },
  { id: 4, label: 'Votação' },

  { id: 147, label: 'Apolo' },
  { id: 49, label: 'Box' },
  { id: 8, label: 'Chronus' },
  { id: 36, label: 'CMS' },
  { id: 154, label: 'DOE ' },
  { id: 75, label: 'Égide' },
  { id: 99, label: 'ELK' },
  { id: 171, label: 'GLauto' },
  { id: 143, label: 'Hermes' },
  { id: 107, label: 'Itinerantes' },
  { id: 79, label: 'Luna Admin' },
  { id: 84, label: 'Luna Chatbot' },
  { id: 152, label: 'Luna Web' },
  { id: 10, label: 'Odin' },
  { id: 25, label: 'Plantão' },
  { id: 78, label: 'Portal do Conhecimento' },
  { id: 47, label: 'Postify' },
  { id: 32, label: 'SAED' },
  { id: 67, label: 'Signo' },
  { id: 35, label: 'Site' },
  { id: 129, label: 'SolarMentos' },
  { id: 71, label: 'Susie' },
  { id: 696, label: 'Encurtador' },
  { id: 702, label: 'Omni' },
  { id: 704, label: 'Cerberus' },

  { id: 27, label: 'Solar' },
  { id: 227, label: 'Solar - Frontend - Descontinuado' },
  { id: 709, label: 'Solar - Frontend' },
  { id: 175, label: 'Solar - Livre' },
  { id: 151, label: 'Solar - Netuno' },
  { id: 66, label: 'Solar - ProcAPI' },
  { id: 162, label: 'Solar - Sid (Backend)' },
  { id: 163, label: 'Solar - Sid (Frontend)' },
  { id: 138, label: 'Solar - Thoth Signer(API certificado digital A1)' },
];
