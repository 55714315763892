import { Route, Switch } from 'react-router-dom';

import Boards from './pages/Boards';
import IssuesCharts from './pages/IssuesCharts';
import LabelsCharts from './pages/LabelsCharts';

function Routes(): JSX.Element {
  return (
    <Switch>
      <Route path="/" component={IssuesCharts} exact />
      <Route path="/boards" component={Boards} exact />
      <Route path="/labels-charts" component={LabelsCharts} exact />
    </Switch>
  );
}

export default Routes;
