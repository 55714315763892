import { useEffect, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';

import HighChartsIssues from '../components/HighChartsIssues';

// import HighChartsSpent from '../components/HighChartsSpent';

import { useAppSelector } from '../app/hooks';

import { selectDev } from '../features/dev/devSlice';
import { selectProject } from '../features/project/projectSlice';
import { selectMilestone } from '../features/milestones/milestoneSlice';

import { Issue } from '../types';

function IssuesCharts(): JSX.Element {
  const dev = useAppSelector(selectDev);
  const project = useAppSelector(selectProject);
  const milestone = useAppSelector(selectMilestone);

  const { data: issuesData, status: issuesStatus } = useAppSelector(
    state => state.issues,
  );
  const { data: mergesData, status: mergesStatus } = useAppSelector(
    state => state.mergeRequests,
  );

  const [tasks, setTasks] = useState<Issue[]>([]);

  const filterTasks = () => {
    const issues = [...issuesData, ...mergesData].sort((a, b) => {
      const x = a.assignee.name.toLowerCase();
      const y = b.assignee.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    if (project.id !== 0 && dev.id === 0) {
      setTasks(issues.filter(issue => issue.project_id === project.id));
    }

    if (dev.id !== 0 && project.id === 0) {
      console.log('aqui');
      setTasks(issues.filter(issue => issue.assignee.id === dev.id));
    }

    if (project.id !== 0 && dev.id !== 0) {
      setTasks(
        issues.filter(
          issue =>
            issue.assignee.id === dev.id && issue.project_id === project.id,
        ),
      );
    }

    if (project.id === 0 && dev.id === 0) {
      setTasks(issues);
    }
  };

  useEffect(() => {
    if (issuesStatus === 'idle' && mergesStatus === 'idle') {
      filterTasks();
    }
  }, [issuesStatus, mergesStatus]);

  useEffect(() => {
    filterTasks();
  }, [dev, project]);

  if (issuesStatus === 'loading' || mergesStatus === 'loading') {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '200px',
          alignItems: 'end',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {tasks.length > 0 && (
        <>
          <HighChartsIssues issues={tasks} title={milestone || ''} />

          {/* <HighChartsSpent issues={tasks} title={milestone || ''} /> */}
        </>
      )}
    </Box>
  );
}

export default IssuesCharts;
