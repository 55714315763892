import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

import { getMergeRequests } from '../../services/api';

import { Dev, Issue } from '../../types';

export interface MergeRequestsState {
  data: Issue[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: MergeRequestsState = {
  data: [],
  status: 'idle',
};

interface Data {
  milestone: string;
  devs: Dev[];
}

export const fetchMergeRequests = createAsyncThunk(
  'mergeRequests/fetchMergeRequests',
  async (data: Data) => {
    const response = await getMergeRequests(data.milestone, data.devs);
    return response;
  },
);

export const mergeRequests = createSlice({
  name: 'mergeRequests',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(fetchMergeRequests.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchMergeRequests.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.data = payload;
      });
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectMergeRequests = (state: RootState) =>
  state.mergeRequests.data;

export default mergeRequests.reducer;
