import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export interface MilestoneState {
  data: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: MilestoneState = {
  data: '',
  status: 'idle',
};

export const milestoneSlice = createSlice({
  name: 'milestone',
  initialState,
  reducers: {
    setMilestone: (state, action: PayloadAction<string>) => {
      state.data = action.payload;
    },
  },
});

export const { setMilestone } = milestoneSlice.actions;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectMilestone = (state: RootState) => state.milestone.data;

export default milestoneSlice.reducer;
