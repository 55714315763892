import Highcharts from 'highcharts';

import HighchartsReact from 'highcharts-react-official';

import { Issue } from '../types';

interface Data {
  categories: string[];
  opened: number[];
  closed: number[];
}

interface HighChartsIssues {
  issues: Issue[];
  title: string;
}

function HighChartsIssues({ issues, title }: HighChartsIssues): JSX.Element {
  const data = issues.reduce(
    (acc: Data, issue) => {
      if (
        acc.categories.find(i => i === issue.assignee.name) !==
        issue.assignee.name
      ) {
        acc.categories.push(issue.assignee.name);

        acc.opened.push(
          issues.filter(
            i => i.assignee.id === issue.assignee.id && i.state === 'opened',
          ).length,
        );

        const close = issues.filter(
          i =>
            i.assignee.id === issue.assignee.id &&
            (i.state === 'closed' || i.state === 'merged'),
        ).length;

        acc.closed.push(close);
      }

      return acc;
    },
    {
      categories: [],
      opened: [],
      closed: [],
    },
  );

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: `Tarefas ${title.toLowerCase()}`,
    },
    xAxis: {
      categories: data.categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Total Tarefas',
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'gray',
        },
      },
    },
    legend: {
      align: 'right',
      x: -20,
      verticalAlign: 'top',
      y: 20,
      floating: true,
      backgroundColor: 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: 'Abertas',
        data: data.opened,
      },
      {
        name: 'Fechadas',
        data: data.closed,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return <HighchartsReact options={options} highcharts={Highcharts} />;
}

export default HighChartsIssues;
