import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import devReducer from '../features/dev/devSlice';
import devsReducer from '../features/dev/devsSlice';
import issuesReducer from '../features/issues/issuesSlice';
import projectReducer from '../features/project/projectSlice';
import projectsReducer from '../features/project/projectsSlice';
import milestoneReducer from '../features/milestones/milestoneSlice';
import milestonesReducer from '../features/milestones/milestonesSlice';
import mergeRequestsReducer from '../features/mergeRequests/mergeRequestsSlice';

export const store = configureStore({
  reducer: {
    dev: devReducer,
    devs: devsReducer,
    issues: issuesReducer,
    project: projectReducer,
    projects: projectsReducer,
    milestone: milestoneReducer,
    milestones: milestonesReducer,
    mergeRequests: mergeRequestsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
