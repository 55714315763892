import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

import { Project } from '../../types';

export interface ProjectsState {
  data: Project[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ProjectsState = {
  data: [],
  status: 'idle',
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<Project[]>) => {
      state.data = action.payload;
    },
  },
});

export const { setProjects } = projectsSlice.actions;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectProjects = (state: RootState) => state.projects.data;

export default projectsSlice.reducer;
