import { Link as RouterLink } from 'react-router-dom';

import { Leaderboard, TableRows } from '@mui/icons-material';

import { AppBar, Box, Link, Stack, Toolbar } from '@mui/material';

function Header(): JSX.Element {
  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          background: '#FFF',
          color: '#333',
        }}
      >
        <Toolbar>
          <Link
            component={RouterLink}
            to="/"
            underline="none"
            variant="h6"
            color="inherit"
            sx={{ flexGrow: 1 }}
          >
            Gestor de Tarefas
          </Link>

          <Stack direction="row" spacing={{ sm: 1, md: 3 }}>
            <Link
              component={RouterLink}
              to="/"
              underline="none"
              variant="button"
              title="Gráficos"
            >
              <Stack direction="row" spacing={{ sm: 1, md: 3 }}>
                <Leaderboard />

                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  Gráficos
                </Box>
              </Stack>
            </Link>

            <Link
              component={RouterLink}
              to="/boards"
              underline="none"
              variant="button"
              title="Quadros"
            >
              <Stack direction="row" spacing={{ sm: 1, md: 3 }}>
                <TableRows />
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>Quadros</Box>
              </Stack>
            </Link>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
