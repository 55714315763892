import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

import { getMilestone } from '../../services/api';

import { Milestone } from '../../types';

export interface MilestonesState {
  data: Milestone[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: MilestonesState = {
  data: [],
  status: 'idle',
};

export const fetchMilestones = createAsyncThunk(
  'milestones/fetchMilestones',
  async () => {
    const response = await getMilestone();
    return response;
  },
);

export const milestonesSlice = createSlice({
  name: 'milestones',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(fetchMilestones.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchMilestones.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.data = payload;
      });
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectMilestones = (state: RootState) => state.milestones.data;

export default milestonesSlice.reducer;
