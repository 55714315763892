import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

// import { HourglassBottomRounded } from '@mui/icons-material';

import { Issue } from '../types';

interface Board {
  label: string;
  name: string;
  color: string;
  text_color: string;
}

interface CardBoardProps {
  issues: Issue[];
  board: Board;
}

function CardBoard({ issues, board }: CardBoardProps): JSX.Element {
  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: board.text_color, color: board.color }}
            aria-label={board.name}
          >
            {issues.length}
          </Avatar>
        }
        title={board.name}
        style={{
          background: board.color,
          color: board.text_color,
        }}
        titleTypographyProps={{ fontSize: '1.25rem' }}
      />

      <CardContent sx={{ overflow: 'auto', maxHeight: 500 }}>
        {issues.map(issue => (
          <Paper key={issue.id} elevation={3} sx={{ marginTop: 1, p: 1 }}>
            <Typography variant="body2" color="text.secondary">
              {issue.title}
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              marginTop={1}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Chip
                  label={issue.project.name}
                  size="small"
                  sx={{ marginRight: 1, background: '#bdbdbd' }}
                />

                {/* {issue.time_stats.human_total_time_spent && (
                  <Chip
                    size="small"
                    icon={<HourglassBottomRounded />}
                    label={issue.time_stats.human_total_time_spent}
                    sx={{ marginRight: 1, background: '#e0e0e0' }}
                  />
                )} */}
                <Chip
                  label="GitLab"
                  size="small"
                  color="default"
                  variant="outlined"
                  component="a"
                  href={issue.web_url}
                  target="_blank"
                  clickable
                />
              </Box>

              <Avatar
                sx={{ width: 24, height: 24 }}
                src={issue.assignee.avatar_url}
                alt={issue.assignee.name}
              />
            </Stack>
          </Paper>
        ))}
      </CardContent>
    </Card>
  );
}

export default CardBoard;
